// Generated by Framer (58a021c)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, RichText, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const enabledGestures = {a5LeUrxG7: {hover: true, pressed: true}};

const cycleOrder = ["a5LeUrxG7"];

const variantClassNames = {a5LeUrxG7: "framer-v-8co5ho"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const transformTemplate = (_, t) => `translate(-50%, -50%) ${t}`

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; title?: string }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "a5LeUrxG7", title: tdON3eYTM = "VOLTAR", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "a5LeUrxG7", enabledGestures, transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-f8qmB", classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-8co5ho", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"a5LeUrxG7"} ref={ref} style={{...style}} {...addPropertyOverrides({"a5LeUrxG7-hover": {"data-framer-name": undefined}, "a5LeUrxG7-pressed": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "Q1VTVE9NO0Flb25payBCb2xk", "--framer-font-family": "\"Aeonik Bold\", sans-serif", "--framer-font-size": "18px", "--framer-line-height": "160%", "--framer-text-color": "var(--extracted-r6o4lv)"}}>VOLTAR</motion.p></React.Fragment>} className={"framer-dn6kjz"} data-framer-name={"[O] Votar"} fonts={["CUSTOM;Aeonik Bold"]} layoutDependency={layoutDependency} layoutId={"EI4e_g6dc"} style={{"--extracted-r6o4lv": "rgba(15, 23, 42, 0.5)", "--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline", "--framer-paragraph-spacing": "0px", opacity: 0.65}} text={tdON3eYTM} transformTemplate={transformTemplate} variants={{"a5LeUrxG7-hover": {opacity: 0.35}, "a5LeUrxG7-pressed": {opacity: 0.35}}} verticalAlignment={"top"} withExternalLayout/></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-f8qmB [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-f8qmB .framer-betcqm { display: block; }", ".framer-f8qmB .framer-8co5ho { height: 29px; overflow: hidden; position: relative; width: 65px; }", ".framer-f8qmB .framer-dn6kjz { cursor: pointer; flex: none; height: auto; left: 48%; position: absolute; top: 48%; white-space: pre; width: auto; }", ".framer-f8qmB .framer-v-8co5ho .framer-8co5ho { cursor: pointer; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 29
 * @framerIntrinsicWidth 65
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"b5Ih1EQGG":{"layout":["fixed","fixed"]},"RUyfkhse1":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"tdON3eYTM":"title"}
 */
const Framerje52XXPoR: React.ComponentType<Props> = withCSS(Component, css, "framer-f8qmB") as typeof Component;
export default Framerje52XXPoR;

Framerje52XXPoR.displayName = "BackButton";

Framerje52XXPoR.defaultProps = {height: 29, width: 65};

addPropertyControls(Framerje52XXPoR, {tdON3eYTM: {defaultValue: "VOLTAR", displayTextArea: false, title: "Title", type: ControlType.String}} as any)

addFonts(Framerje52XXPoR, [{family: "Aeonik Bold", moduleAsset: {localModuleIdentifier: "local-module:canvasComponent/je52XXPoR:default", url: "assets/NESCaYIQ0a59WC034Nrdje8WPUM.ttf"}, url: new URL("assets/NESCaYIQ0a59WC034Nrdje8WPUM.ttf", import.meta.url).href}])